import './App.scss';
// @ts-ignore
import logo from '../src/resources/img/logo.jpg'
import {LinkedinOutlined, MediumOutlined, MediumWorkmarkOutlined, TwitterOutlined} from "@ant-design/icons";

function Home() {
    return (
        <>


            <div className="container">
                <div className="drops">
                    <div className="drop drop-1"></div>
                    <div className="drop drop-2"></div>
                    <div className="drop drop-5"></div>
                </div>
                <form >
                        <div>
                            <h3 className=" text-4xl font-extrabold tracking-tight text-[#ace3dc] lg:text-3xl">
                                Mmetja P. Kekana
                            </h3>

                            <p className="mt-1 pb-0 mb-2 text-xs font-medium text-gray-200">Founder & Factotum at <a
                                target={'_blank'} href={'https://www.unit326.com/'}>Unit
                                326</a></p>

                            <p className={'pt-0 m-2 text-gray-100'}>Software Architect & engineer  with a strong interest in fintech, system architecture, and constantly working on endless side projects
                            </p>
                        </div> <div>
                            <h5 className=" text-1xl text-start font-extrabold tracking-tight text-[#ace3dc] lg:text-1xl">
                            Writing:
                            </h5>

                            <p className="mt-1 text-start pb-0 mb-5 text-xs font-medium text-gray-200"> <a
                                target={'_blank'} href={'https://medium.com/@mmetjakekana/spring-boot-microservice-monitoring-with-prometheus-and-grafana-6c4c275a3c7b'}>Springboot monitoring with Prometheus and Granfana</a></p>


                        </div>
                    <dl className="mt-0 pl-10 pr-10 pb-10 flex gap-4 sm:gap-6">
                        <div className="flex flex-col-reverse">

                            <dd className="text-md text-gray-500">

                                <a target={'_blank'}
                                   href={'https://www.linkedin.com/in/mmetja-kekana-95801a150/'}>
                                    <LinkedinOutlined className={'p-2 text-gray-100'}/>
                                </a>

                                <a target={'_blank'}
                                   href={'https://twitter.com/MmetjaKekana'}>
                                    <TwitterOutlined className={'p-2 text-gray-100'}/>
                                </a>

                                <a target={'_blank'}
                                   href={'https://medium.com/@mmetjakekana'}>
                                    <MediumOutlined className={'p-2 text-gray-100'}/></a>
                            </dd>
                            <dt className="text-md font-medium text-[#ace3dc]">Connect</dt>
                        </div>


                    </dl>
                </form>

                <div className="drops">
                    <div className="drop drop-1"></div>
                    <div className="drop drop-2"></div>
                    <div className="drop drop-3"></div>
                    <div className="drop drop-4"></div>
                    <div className="drop drop-5"></div>
                </div>
            </div>
        </>
    );
}

export default Home;
